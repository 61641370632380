<template>
	<!-- Login -->
	<div class="login">
		<el-form :model="user" ref="user" label-position="top" :rules="rules" status-icon>
			<el-alert show-icon title="Email y contraseña no coinciden" type="error" v-if="invalidCredentials" style="margin-bottom: 20px"> </el-alert>
			<el-row :gutter="10">
				<el-col :span="24">
					<el-form-item prop="email">
						<el-input v-model="user.email" placeholder="Email" maxlength="100" />
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item prop="password">
						<el-input type="password" v-model="user.password" autocomplete="off" placeholder="Contraseña"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item>
						<el-button type="primary" @click.prevent="validateLogin('user'), login()">Entrar</el-button>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
	</div>
</template>

<script>
import { mapActions } from "vuex";
import store from "../../store";

export default {
	data() {
		let validatePass = (_, value, callback) => {
			if (value.length < 8) {
				callback(new Error("La contraseña tiene que ser mayor a 8 caracteres"));
			} else {
				callback();
			}
		};
		return {
			user: {
				email: "",
				password: "",
			},
			rules: {
				email: [
					{ required: true, message: "El email es requerido.", trigger: "blur" },
					{ type: "email", message: "Por favor ingrese un email correcto.", trigger: "blur" },
				],
				password: [
					{ required: true, message: "La contraseña es requerida." },
					{ validator: validatePass, trigger: "blur" },
				],
			},
		};
	},
	computed: {
		invalidCredentials() {
			return store.getters["Auth/invalidCredentials"];
		},
	},
	methods: {
		...mapActions({
			loginUser: "Auth/loginUser",
		}),
		async login() {
			await this.loginUser(this.user);
		},
		validateLogin(user) {
			this.$refs[user].validate(valid => {
				if (!valid) {
					return false;
				}
			});
		},
	},
};
</script>

<style>
</style>


